.testa{background-image:url(./images/cover_bg_3.jpg);}
.foto-profilo{background: url(./images/user-3.jpg);}
.arancio{color:rgb(255, 144, 0);font-size: 1.25rem;}
.blog0{background: url(./images/web-consultant.jpg);}

.demo1{background-image: url(./projects/random-password-generator.jpg);}
.demo2{background-image: url(./projects/qr-code-generator.jpg);}
.demo3{background-image: url(./projects/corsi-react.jpg);}
.demo4{background-image: url(./projects/compressir.jpg);}
.demo44{background-image: url(./projects/scadenze.jpg);}
.demo5{background-image: url(./projects/amazon-scraper.jpg);}
.demo6{background-image: url(./projects/weddo-gestionale-wedding-planner.jpg);}

.border{margin: 10rem 47% 0 47%;border:1px solid #FFF;border-radius:2rem;text-align:center;padding-left:-2%;padding-top:0.5rem;}
.border a{color:#FFF;text-align:center;padding:18rem 0 0 0;background-color:transparent;}

.client1{background-image: url(./portfolio/atc-torino.jpg);}
.client2{background-image: url(./portfolio/crsaluzzo.jpg);}
.client3{background-image: url(./portfolio/idea-business.jpg);}
.client4{background-image: url(./portfolio/intesa-sanpaolo.jpg);}
.client5{background-image: url(./portfolio/l-oreal-saipo.jpg);}
.client6{background-image: url(./portfolio/navale-assicurazioni.jpg);}
.client7{background-image: url(./portfolio/your-next-web-project.jpg);}
.client8{background-image: url(./portfolio/weddo.jpg);}
.client9{background-image: url(./portfolio/compressir.jpg);}
.client10{background-image: url(./portfolio/destination-wedding-umbria.jpg);}
.client11{background-image: url(./portfolio/verynormapeople.jpg);}
.client12{background-image: url(./portfolio/ponthouse.jpg);}

.blog1{background-image: url(./blog/padlock-ssl.jpg);}
.blogg1{background-image: url(./blog/padlock-ssl.jpg);}
.blog2{background-image: url(./blog/blockchain.jpg);}
.blogg2{background-image: url(./blog/blockchain.jpg);}
.blog3{background-image: url(./blog/api.jpg);}
.blogg3{background-image: url(./blog/api.jpg);}
.blog4{background-image: url(./blog/meta-paid.jpg);}
.blogg4{background-image: url(./blog/meta-paid.jpg);}

.video1{background-image: url(./images/cover_bg_1.jpg);}

.bar1{width:95%}
.bar2{width:92%}
.bar3{width:93%}
.bar4{width:94%}
.bar5{width:88%}
.bar6{width:84%}

.text-white{color:#FFF;margin-top:50%;}
textarea{width:100%; height: 150px; min-height:180px;}
.flag{width:4vh;height:auto;}
.bordered{border: 1px solid #fff;border-radius: 16px;padding:1rem 2rem;background-color:rgba(0, 0, 0, 0.2) ;}
.mb-3{margin-bottom: 3rem;}
.fh5co-social-icons li {margin: 2px;}