$font-primary: 'Space Mono', Arial, serif;
$font-secondary: 'Kaushan Script', cursive;



// Overrides
$grid-gutter-width: 40px !default; 
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;

$brand-primary: #FF9000 !default;
$brand-scuro: #2F3C4F !default;

$brand-white: #fff;
$brand-black: #000;
$brand-darker: #444;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #818892;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #4c4a8a;
$brand-bg-color: $brand-white;

$brand-bg-dark: #2F3C4F;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom:       30px !default;



// Mixin
@mixin translateX($translatex) {
	-moz-transform: translateX($translatex);
	-webkit-transform: translateX($translatex);
	-ms-transform: translateX($translatex);
	-o-transform: translateX($translatex);
	transform: translateX($translatex);
}
@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: 		all $transition ease;
    transition:         all $transition ease;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin flex() {
	display: -webkit-box;      
  	display: -moz-box;         
  	display: -ms-flexbox;      
  	display: -webkit-flex;     
  	display: flex;             
}
@mixin flexwrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; 
	-moz-flex-wrap: wrap; 
}

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?srf3rx');
	src:url('../fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
		url('../fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icomoon() {
	
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}

// Import 
@import 'bootstrap/mixins';
@import 'bootstrap/variables';




/* =======================================================
*
* 	Template Style 
*
* ======================================================= */

body {
	font-family: $font-primary;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.7;
	color: darken($brand-white,70%);
	background: #fff;
}
#page {
	position: relative;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	@include transition(.5s);
	.offcanvas & {
		overflow: hidden;	
		position: absolute;
		
		&:after {
			@include transition(2s);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 101;
			background: rgba(0,0,0,.7);
			content: "";
		}
	}
}
a {
	color: $brand-primary;
	@include transition(.5s);
	&:hover, &:active, &:focus {
		color: $brand-primary;
		outline: none;
		text-decoration: none;
	}
}
p {
	margin-bottom: 30px;
}

h1, h2, h3, h4, h5, h6, figure {
	color: $brand-black;
	font-family: $font-primary;
	font-weight: 400;
	margin: 0 0 20px 0;
}
::-webkit-selection {
  color: $brand-white;
  background: $brand-primary;
}

::-moz-selection {
  color: $brand-white;
  background: $brand-primary;
}
::selection {
  color: $brand-white;
  background: $brand-primary;
}


#fh5co-header,
.fh5co-cover {
	background-color: transparent;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	height: 600px;
	width: 100%;
	.overlay{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba($brand-primary,.9);
	}
	.display-t{
		width: 100%;
		display: table;
	}
	.display-tc {
		display: table-cell;
		vertical-align: middle;
		height: 600px;
		h1, h2, h3 {
			margin: 0;
			padding: 0;
			color: rgba(255,255,255,1);
		}
		h1 {
			font-family: $font-secondary;
			margin-bottom: 30px;
			font-size: 50px;
			line-height: 1.3;
			font-weight: 300;
			-webkit-transform: rotate(-5deg);
			-moz-transform: rotate(-5deg);
			-ms-transform: rotate(-5deg);
			-o-transform: rotate(-5deg);
			transform: rotate(-5deg);
			span{
				padding: 4px 15px;
				position: relative;
				&:before{
					position: absolute;
					top: 40px;
					left: 0;
					width: 30px;
					height: 4px;
					content: '';
					background: $brand-white;
					margin-left: -30px;
				}
				&:after{
					position: absolute;
					top: 40px;
					right: 0;
					width: 30px;
					height: 4px;
					content: '';
					background: $brand-white;
					margin-right: -30px;
				}
			}
			@media screen and (max-width: $screen-sm) {
				font-size: 34px;
				span{
					&:before{
						top: 28px;
						width: 20px;
						height: 3px;
						margin-left: -15px;
					}
					&:after{
						top: 28px;
						width: 20px;
						height: 3px;
						margin-right: -15px;
					}
				}
			}
		}
		h2 {
			font-size: 20px;
			line-height: 1.5;
			margin-bottom: 30px;
		}
		h3{
			font-size: 16px;
			@media screen and (max-width: $screen-sm) {
				font-size: 14px;
			}
			span{
			}
		}
		.profile-thumb{
			background-size: cover !important;
			background-position: center center;
			background-repeat: no-repeat;
			position: relative;
			height: 200px;
			width: 200px;
			margin: 0 auto;
			margin-bottom: 30px;
			// border: 1px solid rgba($brand-white,1);
			@include border-radius(50%);
		}
		.fh5co-social-icons {
			li {
				a {
					color: $brand-white;
					i {
						font-size: 30px;
					}
				}
			}
		}
	}
}


#fh5co-features {
	background: $brand-primary;
	h2 {
		color: $brand-white;
	}
	.services-padding{
		padding: 7em 0;
	}
	.feature-left {
		margin-bottom: 40px;
		float: left;
		@media screen and (max-width: $screen-md) {
			margin-bottom: 30px;
		}
		.icon {
			display: table;
			text-align: center;
			width: 100px;
			height: 100px;
			margin: 0 auto;
			background: $brand-white;
			margin-bottom: 20px;
			@include border-radius(50%);
			i{
				font-size: 50px;
				display: table-cell;
				vertical-align: middle;
				height: 100px;
				color: $brand-primary !important;
			}
		}
		.feature-copy {
			width: 100%;
		}
		h3 {
			font-size: 24px;
			font-weight: 400;
			color: $brand-white;
		}
		p {
			font-size: 16px;
			color: rgba(255,255,255,.7);
			&:last-child {
				margin-bottom: 0;
			}
			a{
				color: $brand-black !important;
			}
		}
	}
}


#fh5co-about,
#fh5co-resume,
#fh5co-skills,
#fh5co-started,
#fh5co-work,
#fh5co-blog,
#fh5co-pricing,
#fh5co-contact{
	padding: 7em 0;
	clear: both;
	@media screen and (max-width: $screen-sm) {
		padding: 3em 0;
	}
}
#fh5co-started{
	border-bottom: none;
}

.fh5co-bg-color{
	// background: #233142;

}
.fh5co-bg-dark{
	background: $brand-bg-dark;
	background: $brand-primary;
	.fh5co-heading{
		h2{
			color: $brand-white !important;
		}
	}
}
.fh5co-bg-black{
	background: $brand-scuro;
	.fh5co-heading{
		h2{
			color: $brand-white !important;
		}
	}
}


.info{
	margin: 0;
	padding: 0;
	width: 90%;
	float: left;
	@media screen and (max-width: $screen-sm) {
		margin-bottom: 3em;
	}
	li{
		width: 100%;
		float: left;
		list-style: none;
		padding: 10px 0;
		&:first-child{
			padding-top: 0;
		}
		.first-block{
			width: 40%;
			display: inline-block;
			float: left;
			color: $brand-black;
			font-weight: bold;
		}
		.second-block{
			width: 60%;
			display: inline-block;
			color: rgba($brand-black,.5);
		}
	}
}

.fh5co-social-icons {
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		list-style: none;
		@include inline-block;
		a {
			@include inline-block;
			color: $brand-primary;
			padding-left: 10px;
			padding-right: 10px;
			i {
				font-size: 20px;
			}
		}
	}
}

#fh5co-about{
	.fh5co-social-icons {
		margin: 0;
		padding: 0;
		li {
			padding: 0;
			list-style: none;
			@include inline-block;
			a {
				@include inline-block;
				color: $brand-white;
				background: $brand-bg-dark;
				padding: 10px 10px 2px 10px;
				@include border-radius(2px);
				i {
					font-size: 20px;
				}
				&:hover{
					background: $brand-primary;
				}
			}
		}
	}
}


.fh5co-heading {
	margin-bottom: 5em;
	&.fh5co-heading-sm {
		margin-bottom: 2em;
	}
	h2 {
		font-size: 40px;
		margin-bottom: 20px;
		line-height: 1.5;
		color: $brand-black;
	}
	p {
		font-size: 18px;
		line-height: 1.5;
		color: #828282;
	}
	span{
		display: block;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 2px;
	}
}


// TIMELINE
.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    &:before {
		top: 20px;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 2px;
		background-color: lighten($brand-black,90%);
		left: 50%;
		margin-left: 0px;
		@media screen and (max-width: $screen-sm) {
			margin-left: -64px;
		}
		@media screen and (max-width: $screen-xs) {
			margin-left: -64px;
		}
	}
	.timeline-heading{
		@media screen and (max-width: $screen-sm) {
			margin-bottom: 30px;
		}
		>div{
			h3{
				display: inline-block;
				padding: 7px 15px;
				font-size: 18px;
				text-transform: uppercase;
				letter-spacing: 5px;
				font-weight: bold;
				background: lighten($brand-black,90%);
				@include border-radius(4px);
				@media screen and (max-width: $screen-sm) {
					float: left;
					margin-bottom: 0;
				}
			}
		}
	}
	> li{
		margin-bottom: 20px;
		position: relative;
		@media screen and (max-width: $screen-sm) {
			margin-bottom: 4em;	
		}
		&:before, &:after{
			content: " ";
			display: table;
		}
		&:after{
			clear: both;
		}
		> .timeline-panel {
			width: 45%;
			float: left;
			margin-bottom: 0;
			position: relative;
			@include border-radius(4px);
			@media screen and (max-width: $screen-sm) {
				width: 85% !important;
			}
			@media screen and (max-width: $screen-xs) {
				width: 75% !important;
				&:before{
					top: 30px;
				}
				&:after{
					top: 31px;
				}
			}
		}
		> .timeline-badge {
			color: #fff;
			width: 44px;
			height: 44px;
			line-height: 50px;
			font-size: 1.4em;
			text-align: center;
			position: absolute;
			top: 40px;
			left: 50%;
			margin-left: -21px;
			background-color: $brand-primary;
			z-index: 100;
			display: table;
			@include border-radius(50%);
			@media screen and (max-width: $screen-sm) {
				margin-left: -10px !important;
			}
			@media screen and (max-width: $screen-md) {
				margin-left: -10px !important;
			}
			i{
				display: table-cell;
				vertical-align: middle;
				height: 44px;
				font-size: 18px;
			}
		}
		&.timeline-unverted{
			text-align: right;
			@media screen and (max-width: $screen-sm) {
				text-align: left;
			}
		}
		&.timeline-inverted {
			> .timeline-panel {
				float: right;
				&:before {
					border-left-width: 0;
					border-right-width: 15px;
					left: -15px;
					right: auto;
				}
				&:after {
					border-left-width: 0;
					border-right-width: 14px;
					left: -14px;
					right: auto;
				}
			}
		}
	}
}

.timeline-title {
   margin-top: 0;
}
.company{
	display: block;
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: normal;
	color: lighten($brand-black, 75%);
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: $screen-md) {
	ul.timeline:before {
	  left: 90px;
	}

	ul.timeline > li > .timeline-panel {
	  width: calc(100% - 200px);
	  width: -moz-calc(100% - 200px);
	  width: -webkit-calc(100% - 200px);
	  // width: 40%;
	}

	ul.timeline > li > .timeline-badge {
	  left: 15px;
	  margin-left: 0;
	  top: 16px;
	}

	ul.timeline > li > .timeline-panel {
	  float: right;
	}

	ul.timeline > li > .timeline-panel:before {
	   border-left-width: 0;
	   border-right-width: 15px;
	   left: -15px;
	   right: auto;
	}

	ul.timeline > li > .timeline-panel:after {
	   border-left-width: 0;
	   border-right-width: 14px;
	   left: -14px;
	   right: auto;
	}
}

//PROGRESS
.progress-wrap{
	width: 100%;
	float: left;
	margin-bottom: 10px;
	h3{
		font-size: 16px;
		display: block;
		margin-bottom: 10px;
		float: left;
		width: 100%;
		.name-left{
			float: left;
		}
		.value-right{
			float: right;
		}
	}
}
.progress{
	width: 100%;
	float: left;
	height: 5px;
	box-shadow: none;
}
.progress-bar{
	background-color: $brand-bg-dark;
	height: 5px;
	display: block;
	box-shadow: none;
	&.progress-bar-1{
		background-color: #2980b9 !important;
	}
	&.progress-bar-2{
		background-color: #c0392b !important;
	}
	&.progress-bar-3{
		background-color: #d35400 !important;
	}
	&.progress-bar-4{
		background-color: #8e44ad !important;
	}
	&.progress-bar-5{
		background-color: #27ae60 !important;
	}
}


// CHART
.chart {
  	width: 160px;
  	height: 160px;
  	margin: 0 auto 40px auto;
  	position: relative;
  	text-align: center;
  	span {
  		strong {
  			display: block;
  		}
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -30px;
		width: 100%;
   }
  	canvas{
   	position: absolute;
   	left: 0;
   	top: 0;
  	}
}

// WORK
.work{
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	height: 270px;
	width: 100%;
	display: table;
	overflow: hidden;
	@media screen and(max-width: $screen-sm){
		height: 400px;
	}
	@media screen and(max-width: $screen-xs){
		height: 270px;
	}	
	.desc{
		display: table-cell;
		vertical-align: middle;
		height: 270px;
		background: $brand-white;
		opacity: 0;
		@include transition(.3s);
		h3{
			font-size: 20px;
			-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			transition: transform 0.3s, opacity 0.3s;
			-webkit-transform: translate3d(0, -15px, 0);
			transform: translate3d(0, -15px, 0);
		}
		span{
			display: block;
			color: lighten($brand-black,60%);
			font-size: 14px;
			-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			transition: transform 0.3s, opacity 0.3s;
			-webkit-transform: translate3d(0, 15px, 0);
			transform: translate3d(0, 15px, 0);
		}
		@media screen and(max-width: $screen-sm){
			opacity: 1;
			background: rgba($brand-white,.6);
			h3{
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			span{
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
	}
	&:hover{
		.desc{
			opacity: 1;
			h3{
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			span{
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
	}
}

#fh5co-started {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	float: left;
	width: 100%;
	.fh5co-heading {
		margin-bottom: 0;
		h2 {
			color: $brand-white;
			margin-bottom: 20px !important;
		}
		p{
			color: rgba($brand-white,.8);
		}
	}

	.btn {
		height: 54px;
		border: none!important;
		background: $brand-white;
		color: $brand-primary;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
		padding-left: 50px;
		padding-right: 50px;
	}
}


//PRICING

// BLOG
.fh5co-blog{
	width: 100%;
	float: left;
	margin-bottom: 40px;
	@media screen and (max-width: $screen-sm) {
		width: 100%;
	}
	.blog-bg{
		display: block;
		width: 100%;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
		height: 300px;
		@media screen and(max-width: $screen-sm){
			height: 400px;
		}
		@media screen and(max-width: $screen-xs){
			height: 270px;
		}
	}
	.blog-text {
		margin-bottom: 30px;
		position: relative;
		width: 100%;
		padding: 30px;
		float: left;
		background: $brand-white;

		-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.06);
		-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.06);
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.06);

		@include transition(.3s);

		@media screen and(max-width: $screen-sm){
			padding: 10px;
		}

		span{
			display: inline-block;
			margin-bottom: 20px;
			font-size: 18px !important;
			color: rgba($brand-black,.3);
		}
		.stuff{
			margin: 0;
			padding: 0;
			border-top: 1px solid rgba($brand-black,.05);
			padding-top: 20px;
			li{
				list-style: none;
				display: inline-block;
				margin-left: 10px;
				@media screen and(max-width: $screen-sm){
					font-size: 14px;
				}
				&:first-child{
					margin-left: 0;
				}
				&:last-child{
					float: right;
					a{
						color: $brand-black;
					}
					i{
						padding-right: 0;
						padding-left: 10px;
					}
				}
				i{
					font-size: 14px;
					padding-right: 5px;
				}
			}
		}

		h3{
			font-size: 24px;
			margin-bottom: 20px;
			line-height: 1.5;
			a{
				color: rgba($brand-black,1);
			}
		}
	}
}


#fh5co-consult{
	@include flex();
	@include flexwrap();
	width: 100%;
	float: left;
	.video, .choose{
		display: inline-block;
		@media screen and (max-width: $screen-sm){
			width: 100% !important;
		}
	}
	.video{
		display: table;
		width: 50%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
		@include flex();
		@include flexwrap();
		@media screen and(max-width: $screen-sm){
			display: none;
		}
	}
	.choose{
		width: 50%;
		padding: 4em;
		@media screen and(max-width: $screen-sm){
			width: 100%;
			padding: 4em 1em;
		}
	}
}
.fh5co-video {
	overflow: hidden;
	@media screen and (max-width: $screen-md){
		height: 450px;
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .1);
		@include transition(.5s);
	}
}


#fh5co-footer {
	padding: 50px 0;
	float: left;
	width: 100%;
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Contact
.contact-info{
	margin-bottom: 4em;
	padding: 0;
	li{
		// font-size: 16px;
		list-style: none;
		margin: 0 0 20px 0;
		position: relative;
		padding-left: 40px;
		color: $brand-black;

		i{
			position: absolute;
			top: .3em;
			left: 0;
			font-size: 22px;
			color: rgba($brand-black,.3);
		}
		a{
			color: $brand-black;
		}
		
	}
}
.form-control {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	border: none;
	border: 2px solid rgba(0,0,0,.1);
	font-size: 18px;
	
	font-weight: 300;
	@include border-radius(4px);
	
	&:focus, &:active {
		box-shadow: none;
		border: 2px solid rgba(0,0,0,.8);
	}

}
input[type="text"] {
	height: 50px;
}
.form-group {
	margin-bottom: 30px;
}


// Map
#map {
	width: 100%;
	height: 500px;
	position: relative;
	@media screen and (max-width: $screen-sm) {
		height: 200px;
	}
}

.gototop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	@include transition(.5s);
	&.active {
		opacity: 1;
		visibility: visible;
	}
	a {
		width: 50px;
		height: 50px;
		display: table;
		background: rgba(0,0,0,.8);
		color: $brand-white;
		text-align: center;
		@include border-radius(50%);
		i {
			height: 50px;
			display: table-cell;
			vertical-align: middle;

		}
		&:hover, &:active, &:focus {
			text-decoration: none;
			outline: none;
		}
	}	
}


// Buttons
.btn {
	margin-right: 4px;
	margin-bottom: 4px;
	font-family: $font-primary;
	font-size: 16px;
	font-weight: 400;
	@include border-radius(30px);
	@include transition(.5s);
	padding: 8px 20px;
	&.btn-md {
		padding: 8px 20px!important;
	}
	&.btn-lg {
		padding: 18px 36px!important;
	}
	&:hover, &:active, &:focus {
		box-shadow: none!important;
		outline: none!important;
	}
}
.btn-primary {
	background: $brand-primary;
	color: $brand-white;
	border: 2px solid $brand-primary;
	&:hover, &:focus, &:active {
		background: lighten($brand-primary, 5%)!important;
		border-color: lighten($brand-primary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: $brand-primary;
			color: $brand-white;
		}
	}
}
.btn-success {
	background: $brand-success;
	color: $brand-white;
	border: 2px solid $brand-success;
	&:hover, &:focus, &:active {
		background: darken($brand-success, 5%)!important;
		border-color: darken($brand-success, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-success;
		border: 2px solid $brand-success;
		&:hover, &:focus, &:active {
			background: $brand-success;
			color: $brand-white;
		}
	}
}
.btn-info {
	background: $brand-info;
	color: $brand-white;
	border: 2px solid $brand-info;
	&:hover, &:focus, &:active {
		background: darken($brand-info, 5%)!important;
		border-color: darken($brand-info, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-info;
		border: 2px solid $brand-info;
		&:hover, &:focus, &:active {
			background: $brand-info;
			color: $brand-white;
		}
	}
}
.btn-warning {
	background: $brand-warning;
	color: $brand-white;
	border: 2px solid $brand-warning;
	&:hover, &:focus, &:active {
		background: darken($brand-warning, 5%)!important;
		border-color: darken($brand-warning, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-warning;
		border: 2px solid $brand-warning;
		&:hover, &:focus, &:active {
			background: $brand-warning;
			color: $brand-white;
		}
	}
}
.btn-danger {
	background: $brand-danger;
	color: $brand-white;
	border: 2px solid $brand-danger;
	&:hover, &:focus, &:active {
		background: darken($brand-danger, 5%)!important;
		border-color: darken($brand-danger, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-danger;
		border: 2px solid $brand-danger;
		&:hover, &:focus, &:active {
			background: $brand-danger;
			color: $brand-white;
		}
	}
}

.btn-outline {
	background: none;
	border: 2px solid lighten($brand-black, 50%);
	font-size: 16px;
	@include transition(.3s);
	&:hover, &:focus, &:active {
		box-shadow: none;
	}
}

.btn.with-arrow {
	position: relative;
	@include transition(.3s);
	i {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -8px;
		@include transition(.2s);
	}
	&:hover {
		padding-right: 50px;
		i {
			color: $brand-white;
			right: 18px;
			visibility: visible;
			opacity: 1;
		}
	}
}
// Form Input Field
.form-control {
	box-shadow: none;
	background: transparent;
	border: 2px solid rgba(0, 0, 0, 0.1);
	height: 54px;
	font-size: 18px;
	font-weight: 300;
  	&:active, &:focus {
  		outline: none;
		box-shadow: none;
		border-color: $brand-primary;
  }
}

.row-pb-md {
	padding-bottom: 4em!important;
}
.row-pb-sm {
	padding-bottom: 2em!important;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}
.col-padding {
   padding:6px !important;
   margin: 0px !important;
}

.fh5co-loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(../images/loader.gif) center no-repeat #fff;
}

.animate-box {
	.js & {
		opacity: 0;
	}
}